<template>
    <main class="about">
        <div class="about-main__body">
            <div class="about-main__content">
                <h1 class="about-main__title">Acerca de MEGAdescuentos</h1>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs1"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Cupones de descuento para todo México</h2>

                <p class="about-main__text">
                    MEGAdescuentos nace con la idea de simplificar la búsqueda de
                    <NuxtLink class="about-main__link" to="/marcas"
                        >cupones en las mejores tiendas en México</NuxtLink
                    >. Por ello, nuestro equipo se encarga de buscar y verificar descuentos, los cuales están
                    disponibles de manera gratuita e ilimitada para cualquier usuario que visite nuestro sitio
                    web. La clave de nuestro éxito se encuentra en las actualizaciones diarias, lo cual nos ha
                    permitido ofrecer descuentos de manera eficiente en cualquier temporada del año.
                </p>
                <p class="about-main__text">
                    Por otro lado, también contamos con una sección de blog, en la cual nos encargamos de
                    desarrollar contenido valioso sobre eventos comerciales, ventas especiales, programas de
                    fidelización, métodos de pago, descuentos para estudiantes y todas las novedades que trae
                    consigo el mercado mexicano.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs2"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Extensa red de afiliados</h2>

                <p class="about-main__text">
                    Uno de los puntos fuertes de MEGAdescuentos es que pone a disposición de los usuarios
                    cupones que no están disponibles en ningún otro lugar. Esto es posible gracias a nuestra
                    extensa red de afiliados de Hurtado Deals, que colabora de cerca con Awin, Soicos,
                    Tradetracker, y otros líderes del sector.
                </p>

                <p class="about-main__text">
                    Nuestras decenas de cupones exclusivos son uno de los motivos principales para conseguir
                    ahorros en tiendas, almacenes y marcas de distintos rubros. Además, antes de ser
                    publicado, cada código es probado por nuestro equipo de colaboradores para garantizar que
                    funcione correctamente. De esta manera, podemos ofrecer información precisa sobre el monto
                    mínimo de compra, máximo de descuento, productos en los que aplica y si es para nuevos
                    usuarios o compradores recurrentes.
                </p>

                <p class="about-main__text">
                    Adicionalmente, ofrecemos a nuestros usuarios información detallada y anticipada de las
                    campañas más importantes de distintas marcas. En particular se destacan las Ventas
                    Nocturnas, Promociones de Aniversario, Ofertas Flash, Rebajas de Temporada y más.
                </p>

                <h2 class="about-main__subtitle">Así es como ganamos dinero</h2>

                <p class="about-main__text">
                    Unirte a MEGAdescuentos es gratis para ti, pero mantenerlo actualizado y funcionando
                    diariamente cuesta dinero. ¿Cómo le hacemos? Nuestro sitio web consigue recursos a través
                    de la
                    <NuxtLink
                        class="about-main__link"
                        to="https://www.hurtadodeals.com/"
                        external
                        target="_blank"
                        >red de afiliados de Hurtado Deals</NuxtLink
                    >, integrada por marcas y tiendas que nos pagan una comisión por cada compra que los
                    usuarios hacen usando nuestros enlaces o cupones exclusivos.
                </p>
                <p class="about-main__text">
                    Desde luego, puedes tener la tranquilidad de que nuestras ganancias no representan ningún
                    costo para el comprador; tú siempre podrás aprovechar las ofertas sin costo adicional de
                    ningún tipo. Nuestro beneficio se complementa con nuestro objetivo primordial, que es
                    poner a tu alcance las mejores oportunidades de ahorro.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs3"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Cobertura en eventos comerciales</h2>

                <p class="about-main__text">
                    Cada año, en MEGAdescuentos nos encargamos de dar seguimiento a los eventos comerciales
                    más grandes del país, como el
                    <NuxtLink class="about-main__link" to="/eventos/hot-sale-20">Hot Sale</NuxtLink>, Buen
                    Fin, Black Friday, Singles Day, Cyber Monday, Ventas Nocturnas, y mucho más, para que
                    nuestros miembros accedan a información precisa sobre los mejores descuentos, cupones,
                    ofertas y promociones.
                </p>

                <h2 class="about-main__subtitle">Publicamos cupones verificados</h2>

                <p class="about-main__text">
                    Para ayudarte a encontrar cupones que realmente funcionen, nos encargamos de probar los
                    códigos antes de publicarlos, incluso aquellos que nos han compartido nuestros afiliados.
                    Nuestra intención es ofrecerte información clara sobre el descuento que recibirás, el
                    monto mínimo de compra que debes hacer, y si es posible combinar ofertas y códigos para
                    sumar beneficios.
                </p>

                <p class="about-main__text">
                    Por este motivo, puedes tener la certeza de que cada cupón que encuentres en nuestro sitio
                    te ayudará a conseguir ese ahorro que tanto necesitas. Descubre más información sobre cómo
                    <NuxtLink
                        class="about-main__link"
                        to="/blog/guias-de-ahorro/unete-gana-y-ahorra-te-decimos-por-que-confiar-en-megadescuentos"
                        >revisamos y probamos los cupones</NuxtLink
                    >
                    en MEGAdescuentos.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs4"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Nuestro Equipo</h2>

                <section class="about-main__team">
                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.megadescuentos.aboutPeople1"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Juanca Hurtado</h3>
                                <h3 class="info-content__job">CEO de Hurtado Global Media Group</h3>
                                <nav class="info-content__nav">
                                    <a
                                        class="info-content__link"
                                        href="https://www.linkedin.com/in/juanca-hurtado-55569746/"
                                        rel="nofollow"
                                        target="_blank"
                                        >LinkedIn</a
                                    >
                                    <a
                                        class="info-content__link"
                                        href="https://www.instagram.com/jotahmega/"
                                        rel="nofollow"
                                        target="_blank"
                                        >Instagram</a
                                    >
                                </nav>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Con una amplia experiencia en productos y servicios en tema de ahorro y cupones en
                            línea, Juanca es el líder del equipo de MEGAdescuentos, y el principal encargado
                            de construir y mantener la red de colaboración de afiliados, que permite a nuestro
                            sitio tener códigos exclusivos.
                        </p>
                    </article>

                    <hr class="about-main__team-line" />

                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.megadescuentos.aboutPeople3"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Concepción Pacheco</h3>
                                <h3 class="info-content__job">Content Assistant</h3>
                                <nav class="info-content__nav">
                                    <a
                                        class="info-content__link"
                                        href="https://www.linkedin.com/in/conepacheco/"
                                        rel="nofollow"
                                        target="_blank"
                                        >LinkedIn</a
                                    >
                                </nav>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Se unió al equipo de MEGAdescuentos para monitorear las tiendas y verificar sus
                            cupones. Adicionalmente, Cone hace un excelente trabajo al mantener el contenido
                            de nuestro blog actualizado, gracias a su investigación, nuestra comunidad puede
                            aprovechar al máximo programas de recompensas, recopilación de puntos, membresías,
                            tarjetas y mucho más.
                        </p>
                    </article>
                </section>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
const Route = useRoute()

const {
    public: { origin },
} = useRuntimeConfig()

const description =
    'Aprende más sobre MEGAdescuentos y quién está detrás. Conoce quiénes somos, nuestras actividades y por qué elegirnos.'
const title = 'Quiénes somos | MEGAdescuentos'

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.about {
    &-main {
        @apply m-0 mx-auto space-x-2 lg:container md:mb-10 md:mt-4;
        &__body {
            @apply mx-auto my-4 bg-white lg:container md:rounded-3xl lg:max-w-5xl;
        }
        &__content {
            @apply px-6 py-4;
        }

        &__title {
            @apply mb-6 mt-4 text-center text-[28px] font-medium lg:text-[32px];
        }

        &__subtitle {
            @apply mb-4 text-lg lg:text-[28px];
        }

        &__img {
            @apply mb-6 block w-full object-contain md:mb-8 md:h-[500px];
        }

        &__text {
            @apply mb-6 text-base;
        }

        &__link {
            @apply font-semibold underline;
        }

        &__team {
            @apply mt-8 space-y-8;

            &-line {
                @apply w-full bg-gray-200;
            }

            .team-card {
                @apply flex flex-col gap-2 last:mb-4;

                &__info {
                    @apply flex gap-4;

                    .info-img {
                        @apply h-32 w-32 rounded-3xl object-contain;
                    }

                    .info-content {
                        @apply flex flex-col justify-center;

                        &__name {
                            @apply text-lg font-semibold;
                        }

                        &__job {
                            @apply text-base;
                        }

                        &__nav {
                            @apply flex items-center space-x-3;

                            a {
                                @apply underline;
                            }
                        }
                    }
                }

                &__text {
                    @apply col-span-5 row-start-4 text-base;
                }
            }
        }
    }
}
</style>
